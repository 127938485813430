import Navbar from "./Navbar";
import heroimage from "./imgs/IMG_87075.jpg";
import pcimage from "./imgs/Capture-removebg-preview.png";
import Footer from "./Footer";
import { ReactTyped } from "react-typed";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useState } from "react";

import img1 from "./homeImages/img1.jpg";
import img2 from "./homeImages/img2.jpg";
import img3 from "./homeImages/img3.jpg";
import img4 from "./homeImages/img4.jpg";
import img5 from "./homeImages/img5.jpg";

const Home = () => {
  const imgs = [img1, img2, img3, img4, img5, img1, img2, img3, img4, img5];
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to handle the click event and show the modal
  const handleImageClick = (img) => {
    setSelectedImage(img);
    console.log("Image");
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <Helmet>
        <title>Campus 360</title>
        <meta
          name="description"
          content="Campus 360 is a dynamic and evolving player in Uganda's media and entertainment sector. Our roots are deeply embedded in the belief that media and content have the power to shape perceptions, foster knowledge, and engage communities"
        />
      </Helmet>
      <div className="hero">
        <Navbar />
        <img src={heroimage} alt="" className="hero-bg" />
        <div className="conatiner hero-content md:ml-12">
          <h1 className="text-white text-2xl md:text-7xl font-bold">
            Unlimited
            <ReactTyped
              strings={[
                "Campus news,",
                "Fun stories,",
                "Tech updates,",
                "Fashion trends",
              ]}
              typeSpeed={120}
              loop
              backSpeed={50}
              cursorChar="|"
              showCursor={false}
              className="ml-2 md:ml-4"
            />
          </h1>

          <h1 className="text-white text-2xl md:text-7xl font-bold leading-relaxed">
            games and more.
          </h1>
          <p className="mt-9 text-white md:text-2xl">
            Ready to dive into the 360 Campus experience?
          </p>
          <div className="flex mt-4">
            <Link
              to="/login"
              className=" text-white border border-[#D7387A] hover:bg-[#D7387A] transition px-4 w-1/4 bg-transparent py-3 mx-2  text-center rounded-lg block"
            >
              Sign In
            </Link>
            {/* <Link to='/signup' className="button px-4 py-3 mx-2  text-center rounded-lg block">Get Started</Link> */}
          </div>
          {/* <div className="address flex mt-5">
                  <input type="email" placeholder="Enter Address" className="px-4 py-2  w-1/2 rounded border focus:outline-none " />
                  <Link to='/signup' className="button w-1/3 text-center rounded-lg block">Get Started</Link>
                </div> */}
        </div>
      </div>

      <div className="image-container my-12 px-3 md:my-32 mx-auto">
        <div className="text-white text-center mb-12">
          <h1 className="md:text-3xl text-xl font-bold">
            Join the Coolest Campus and youth Community
          </h1>
          <p>Get the latest trends and insights from your university.</p>
        </div>

        <div className="relative flex flex-col justify-center overflow-hidden">
          <div className="absolute inset-0"></div>
          <div className="pointer-events-none relative flex gap-5 overflow-hidden">
            <div className="animate-marquee flex min-w-full shrink-0 items-center justify-around gap-10">
              {imgs.map((img) => (
                <img
                  key={img + Math.random()}
                  className="aspect-square max-w-[clamp(10rem,28vmin,20rem)] rounded-md object-cover shadow-md"
                  src={img}
                  alt=""
                  onClick={() => handleImageClick(img)}
                />
              ))}
            </div>
            <div
              aria-hidden="true"
              className="animate-marquee flex min-w-full shrink-0 items-center justify-around gap-10"
            >
              {imgs.map((img) => (
                <img
                  key={img + Math.random()}
                  className="aspect-square max-w-[clamp(10rem,28vmin,20rem)] rounded-md object-cover shadow-md"
                  src={img}
                  alt=""
                  onClick={() => handleImageClick(img)}
                />
              ))}{" "}
            </div>
          </div>
        </div>
      </div>

      <div>
        {selectedImage && (
          <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            onClick={closeModal} // Close modal on clicking the background
          >
            <div
              className="relative p-4 bg-white rounded-md"
              onClick={(e) => e.stopPropagation()} // Prevent closing modal on clicking inside
            >
              <img
                src={selectedImage}
                alt="Selected"
                className="max-w-full h-auto rounded-lg"
              />
              <button
                className="absolute top-2 right-2 text-black bg-gray-300 rounded-full p-2"
                onClick={closeModal}
              >
                &times;
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="container mx-auto my-12 md:my-32 px-4">
        <div className="md:grid grid-cols-3 gap-y-10 justify-center
         gap-5">
          <div className="max-w-sm p-6 bg-[#222] border border-gray-200 rounded-lg shadow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
              />
            </svg>

            <Link to="/videos">
              <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                Checkout Our Content
              </h5>
            </Link>
            <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
              Go to this step by step guideline process on how to certify for
              your weekly benefits:
            </p>
            <Link
              to="/videos"
              className="inline-flex font-medium items-center text-[#BF3B7D] hover:underline"
            >
              Video Checkout
              <svg
                className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
                />
              </svg>
            </Link>
          </div>
          <div className="max-w-sm p-6 bg-[#222] border border-gray-200 rounded-lg shadow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"               />
            </svg>

            <Link to="/spaces">
              <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                Checkout Our Content
              </h5>
            </Link>
            <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
              Go to this step by step guideline process on how to certify for
              your weekly benefits:
            </p>
            <Link
              to="/spaces"
              className="inline-flex font-medium items-center text-[#BF3B7D] hover:underline"
            >
              Space Checkout
              <svg
                className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
                />
              </svg>
            </Link>
          </div>
          <div className="max-w-sm p-6 bg-[#222] border border-gray-200 rounded-lg shadow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />

            </svg>

            <Link to="/scholarships">
              <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                Checkout Our Content
              </h5>
            </Link>
            <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
              Go to this step by step guideline process on how to certify for
              your weekly benefits:
            </p>
            <Link
              to="/scholarships"
              className="inline-flex font-medium items-center text-[#BF3B7D] hover:underline"
            >
              Video Checkout
              <svg
                className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
                />
              </svg>
            </Link>
          </div>
          <div className="max-w-sm p-6 bg-[#222] border border-gray-200 rounded-lg shadow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
            </svg>

            <Link to="/merch">
              <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                Checkout Our Content
              </h5>
            </Link>
            <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
              Go to this step by step guideline process on how to certify for
              your weekly benefits:
            </p>
            <Link
              to="/merch"
              className="inline-flex font-medium items-center text-[#BF3B7D] hover:underline"
            >
              Video Checkout
              <svg
                className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
                />
              </svg>
            </Link>
          </div>
          <div className="max-w-sm p-6 bg-[#222] border border-gray-200 rounded-lg shadow col-start-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-white"
            >
               <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z" />
              </svg>

            <Link to="/shows">
              <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                Checkout Our Content
              </h5>
            </Link>
            <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
              Go to this step by step guideline process on how to certify for
              your weekly benefits:
            </p>
            <Link
              to="/shows"
              className="inline-flex font-medium items-center text-[#BF3B7D] hover:underline"
            >
              Video Checkout
              <svg
                className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
                />
              </svg>
            </Link>
          </div> 
        </div>
      </div>

      {/* <div className="games mx-auto text-white container mt-5 p-10 md:p-20 rounded-xl">
          <div className="container md:pb-10 border-b-4 border-orange-900 text-center">
            <h1 className="text-2xl md:text-4xl">Want the best campus games?</h1>
            <p className="md:text-xl my-4 md:my-8">Enjoy immersing games, campus news, tech updates <br /> and more. </p>
            <Link to="/signup" className="button my-3 w-1/2 mx-auto md:py-2 rounded-xl">Create Account</Link>
          </div>
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 items-center">
              <div>
                <img src={pcimage} alt="no image" />
              </div>
              <div className="text-center ">
                <h1 className="text-3xl md:text-5xl">Watch Anywhere. Anytime</h1>
                <p className="md:text-xl mt-1 leading-relaxed">Gain full control of your watchtime experience. Watch all your favourite shows and be part of the cool campus life just on your phone, tablet and laptop. </p>
              </div>
            </div>
          </div>
        </div>   */}
      {/* <div className="container pl-10 pt-10 pb-20">
          <p className="mt-9 text-white text-lg md:text-2xl">Ready to dive into the 360 Campus experience?</p>
          <div className="address flex mt-5">
            <input type="email" placeholder="Enter Address" className="px-4 py-2  w-1/2 rounded border focus:outline-none " />
            <Link to="/signup" className="button w-1/3 text-center rounded-lg block ">Get Started</Link>
          </div>
        </div> */}
<section class="bg-white dark:bg-gray-900">
  <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
      <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Contact Us</h2>
      <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">Got a technical issue? Want to send feedback about a beta feature? Need details about our site? Let us know.</p>
      <form action="#" class="space-y-8">
          <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your email</label>
              <input type="email" id="email" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@flowbite.com" required />
          </div>
          <div>
              <label for="subject" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Subject</label>
              <input type="text" id="subject" class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Let us know how we can help you" required />
          </div>
          <div class="sm:col-span-2">
              <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
              <textarea id="message" rows="6" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a comment..."></textarea>
          </div>
          <button type="submit" class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send message</button>
      </form>
  </div>
</section>
      <Footer />
    </div>
  );
};

export default Home;
