import { Link, useNavigate } from "react-router-dom";
import logo from "./imgs/campus-logo.png";
import { FaCircleUser, FaMagnifyingGlass, FaRegBell } from "react-icons/fa6";
import { FaSignOutAlt, FaCog, FaExclamationCircle } from "react-icons/fa";
import { Offcanvas, Ripple, Dropdown, initTWE } from "tw-elements";
import { tailspin } from "ldrs";

import Search from "./components/Search";
import { useEffect, useState } from "react";
import axios from "axios";

const Navbar = ({ navlinks, page }) => {
  const [notifications, setnotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const login = localStorage.getItem("login");

  const logout = () => {
    if (localStorage.getItem("login")) {
      localStorage.setItem("login", "");
      localStorage.setItem("user", "");
      localStorage.setItem("loginstatus", "Logged Out Successfully!");
      navigate("/login");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    initTWE({ Offcanvas, Ripple, Dropdown });
    fetchNotifications();
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  tailspin.register();
  const fetchNotifications = async () => {
    setIsLoading(true);
    let userId = localStorage.getItem("user");
    try {
      const response = await axios.get(
        "https://api.campus360recreations.com/notifications/fetch_user_notes/" +
          userId
      );

      setnotifications(response.data.notifications);
      // console.log(response.data)
      setIsLoading(false);
    } catch (error) {
      console.log("an error occured" + error);
    }
  };

  const handleNotificationRead = async (id) => {
    try {
      const response = await axios.post(
        "https://api.campus360recreations.com/notifications/is_read",
        { notification_id: id }
      );
      //  console.log(response.data);
      //  if(response.data.status != '0' ){
      //     throw new Error("failed to remove notification")
      //  }
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="navbar">
      <div
className="invisible fixed rounded-3xl pb-2 backdrop-blur-md bg-white/10 bottom-0 right-0 top-0 z-[123045] flex w-60 max-w-full translate-x-full flex-col border-none bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out data-[twe-offcanvas-show]:transform-none dark:bg-body-dark dark:text-white"
tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
        data-twe-offcanvas-init
      >
        <div className="flex items-center justify-between p-4">
          <h5
            className="mb-0 font-semibold md:text-3xl leading-normal"
            id="offcanvasExampleLabel"
          >
            Campus 360
          </h5>
          <button
            type="button"
            className="box-content rounded-none border-none text-neutral-500 hover:text-neutral-800 hover:no-underline focus:text-neutral-800 focus:opacity-100 focus:shadow-none focus:outline-none dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
            data-twe-offcanvas-dismiss
            aria-label="Close"
          >
            <span className="[&>svg]:h-6 [&>svg]:w-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>
        <div className="flex-grow overflow-y-auto ms-4">
          <div></div>
          <div className="relative" data-twe-dropdown-ref>
            <ul className="w-full">
              <li>
                <Link
                  className="w-full  block md:py-3 my-1 py-2 font-bold text-white pl-2 hover:border-[#9844AC] links border-b-2 border-[#9844AC] hover:border-b-0"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className="w-full  block md:py-3 my-1 py-2 font-bold text-white pl-2 hover:border-[#9844AC] links border-b-2 border-[#9844AC] hover:border-b-0"
                  to="/videos"
                >
                  Videos
                </Link>
              </li>
              <li>
                <Link
                  className="w-full  block md:py-3 my-1 py-2 font-bold text-white pl-2 hover:border-[#9844AC] links border-b-2 border-[#9844AC] hover:border-b-0"
                  to="/shows"
                >
                  Shows
                </Link>
              </li>
              <li>
                <Link
                  className="w-full  block md:py-3 my-1 py-2 font-bold text-white pl-2 hover:border-[#9844AC] links border-b-2 border-[#9844AC] hover:border-b-0"
                  to="/spaces"
                >
                  Spaces
                </Link>
              </li>
              <li>
                <Link
                  className="w-full  block md:py-3 my-1 py-2 font-bold text-white pl-2 hover:border-[#9844AC] links border-b-2 border-[#9844AC] hover:border-b-0"
                  to="/merch"
                >
                  Merch
                </Link>
              </li>
              <li>
                <Link
                  className="w-full  block md:py-3 my-1 py-2 font-bold text-white pl-2 hover:border-[#9844AC] links border-b-2 border-[#9844AC] hover:border-b-0"
                  to="/funds"
                >
                  Funds
                </Link>
              </li>
              <li>
                <Link
                  className="w-full  block md:py-3 my-1 py-2 font-bold text-white pl-2 hover:border-[#9844AC] links border-b-2 border-[#9844AC] hover:border-b-0"
                  to="/internship"
                >
                  Scholarships
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {login && (
          <div className="mt-auto ms-4">
            <Link
              to="/profile"
              className="flex mb-3 hover:border-r-4 pl-2 hover:border-[#9844AC] hover:text-[#9844AC]"
            >
              <FaCircleUser className="my-auto text-lg md:text-xl  mr-1" />
              <span className=" ml-1 text-lg">Profile</span>
            </Link>

            <Link
              to="/settings"
              className="flex mb-3 hover:border-r-4 pl-2 hover:border-[#9844AC] hover:text-[#9844AC]"
            >
              <FaCog className="my-auto text-lg md:text-xl mr-1" />
              <span className=" ml-1 text-lg">Settings</span>
            </Link>

            <Link
              to="/help"
              className="flex  mb-3 hover:border-r-4 pl-2 hover:border-[#9844AC] hover:text-[#9844AC]"
            >
              <FaExclamationCircle className="my-auto text-lg md:text-xl mr-1" />
              <span className=" ml-1 text-lg">Help</span>
            </Link>

            <button
              onClick={logout}
              className=" hover:border-r-4 hover:border-[#9844AC] hover:text-[#9844AC] w-full pl-2 text-sm font-norma flex "
              data-twe-dropdown-item-ref
            >
              <FaSignOutAlt className="my-auto text-lg md:text-xl mr-1" />
              <span className=" ml-1 text-lg">Sign Out</span>
            </button>
          </div>
        )}
      </div>
      <div className="container mx-auto flex items-center justify-between">
        <div className="logo">
          
            <img src={logo} alt="" /> 
        </div>
        <Search isOpen={isModalOpen} onClose={closeModal} />

        {page && (
          <div>
            <h1 className="text-white text-lg font-bold md:text-6xl">{page}</h1>
          </div>
        )}

        {navlinks && (
          <div className="flex justify-around grow md:px-10 lg:px-40 text-white text-sm">
            <Link
              to="/"
              className={
                window.location.pathname === "/"
                  ? "primary font-bold mx-1 md:text-xl hover:border-b-4 pb-2  hover:border-[#9844AC] hover:text-[#9844AC]"
                  : "font-bold mx-1 md:text-xl hover:border-b-4 pb-2  hover:border-[#9844AC] hover:text-[#9844AC]"
              }
            >
              Home
            </Link>
            <Link
              to="/videos"
              className={
                window.location.pathname === "/videos"
                  ? "primary font-bold mx-1 md:text-xl hover:border-b-4 pb-2  hover:border-[#9844AC] hover:text-[#9844AC]"
                  : "font-bold mx-1 md:text-xl hover:border-b-4 pb-2  hover:border-[#9844AC] hover:text-[#9844AC]"
              }
            >
              Videos
            </Link>
            <Link
              to="/shows"
              className={
                window.location.pathname === "/shows"
                  ? "primary font-bold mx-1 md:text-xl hover:border-b-4 pb-2  hover:border-[#9844AC] hover:text-[#9844AC]"
                  : "font-bold mx-1 md:text-xl hover:border-b-4 pb-2  hover:border-[#9844AC] hover:text-[#9844AC]"
              }
            >
              Shows
            </Link>
            <Link
              to="/spaces"
              className={
                window.location.pathname === "/spaces"
                  ? "primary font-bold mx-1 md:text-xl hover:border-b-4 pb-2  hover:border-[#9844AC] hover:text-[#9844AC]"
                  : "font-bold mx-1 md:text-xl hover:border-b-4 pb-2  hover:border-[#9844AC] hover:text-[#9844AC]"
              }
            >
              Spaces
            </Link>
          </div>
        )}
        <div>
          {login ? (
            <div className="grid grid-cols-3 text-white gap-x-2 text-xl md:text-4xl md:gap-x-9 mr-4">
              <FaMagnifyingGlass
                className="cursor-pointer"
                onClick={openModal} />
              <div className="relative" data-twe-dropdown-ref>
                <button
                  type="button"
                  id="dropdownMenuButton2"
                  data-twe-dropdown-toggle-ref
                  aria-expanded="false"
                  data-twe-ripple-init
                  data-twe-ripple-color="dark"
                >
                  <FaRegBell />
                  {/* Badge */}
                  <span className="absolute -top-1 md:-top-2 right-0 inline-flex items-center justify-center  md:px-2 md:py-2 text-xs p-[1px] font-bold leading-none text-red-100 bg-red-600 rounded-full">
                    
                    {isLoading ? (<></>) : (
                      <span>
                      {
                        notifications.length
                      }</span>
                    )}
                  </span>
                </button>

                <ul
                  className="absolute  backdrop-blur-md bg-white/10 p-4 z-[1545000] float-left m-0 hidden max-w-min list-none overflow-hidden rounded-lg border-none  bg-clip-padding text-base shadow-lg data-[twe-dropdown-show]:block  "
                  aria-labelledby="dropdownMenuButton2"
                  data-twe-dropdown-menu-ref >
                  <li className="border-b-2 px-24 mb-4">Notifications</li>
                  {isLoading ? (
                    <div className="text-center">
                      <l-tailspin
                        size="60"
                        stroke="5"
                        speed="0.9"
                        color="white"
                      ></l-tailspin>
                    </div>
                  ) : notifications ? (
                    notifications.map((notification, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          handleNotificationRead(notification.id);
                        }}
                        className="border-b-2 py-2 cursor-pointer hover:text-[#BF3B7D]"
                      >
                        {notification.message}
                      </li>
                    ))
                  ) : (
                    <li>No Notifications yet</li>
                  )}
                </ul>
              </div>
              <button
                type="button"
                data-twe-offcanvas-toggle
                data-twe-target="#offcanvasExample"
                aria-controls="offcanvasExample"
                data-twe-ripple-init
                data-twe-ripple-color="light"
              >

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" size-7 md:size-12 cursor-pointer transition hover:text-[#D7387A] text-white">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            </button>
            </div>
          ) : (
            <button
            type="button"
            data-twe-offcanvas-toggle
            data-twe-target="#offcanvasExample"
            aria-controls="offcanvasExample"
            data-twe-ripple-init
            data-twe-ripple-color="light"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-12 cursor-pointer transition hover:text-[#D7387A] text-white">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            </button>

            // <Link
            //   to="/login"
            //   className="button px-2 py-2 md:px-5 md:py-3 rounded-xl mr-2  text-sm md:text-lg"
            // >
            //   Sign In
            // </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
